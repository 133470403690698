/*
 * @description: 
 * @Author: muhaibing
 * @Date: 2024-07-30 10:03:53
 * @LastEditors: muhaibing
 * @LastEditTime: 2024-08-01 11:06:46
 * 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import("@/layout"),
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/home")
      },
      {
        path: "login",
        name: "login",
        component: () => import("@/views/login")
      },
    ]
  },
  { path: "/404", component: () => import("@/views/404") },
  { path: "*", redirect: "/404" }
]
const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})
export default router
