/*
 * @description: 
 * @Author: muhaibing
 * @Date: 2024-07-30 10:03:54
 * @LastEditors: muhaibing
 * @LastEditTime: 2024-11-13 10:26:24
 * 
 */
import router from "./router";
import VueRouter from 'vue-router'
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import getPageTitle from "@/utils/get-page-title";
import { getStore, setStore } from "./utils/storage";
import { getUrlParam } from "./utils";
// import { getSession } from "@/api/user";
NProgress.configure({ showSpinner: false }); // NProgress Configuration
router.beforeEach(async (to, from, next) => {
    console.log(to, from,99999)
    if (to.query.logout) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
      // next("/");
      return;
    }
    // if (
    //   to.path === "/education" ||
    //   to.path === "/news" ||
    //   to.path === "/resources" ||
    //   to.path === "/dataService" ||
    //   to.path === "/help"
    // ) {
    //   next();
    //   return;
    // }
    NProgress.start();
    document.title = getPageTitle(to.meta.title);
    const token = getStore("tokens") || localStorage.getItem("token");
    const Token = getUrlParam("token");
    const userinfo = getStore("userinfo");
    if (Token && (!token || token !== Token)) {
      window.localStorage.setItem("token", Token);
      setStore("tokens", Token);
      // getSession().then(res => {
      //   setStore("userinfo", res.data);
      //   window.location.reload();
      // });
      return;
    }
    if (token || to.matched.some(e => e.meta.isAuth)) {
      if (token && userinfo && to.name === "tourist") next("/home");
      else if (token && userinfo) next();
      else next("/home?logout=true");
      return;
    }
    next();
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
