<!--
 * @description: 
 * @Author: muhaibing
 * @Date: 2024-07-30 10:03:54
 * @LastEditors: muhaibing
 * @LastEditTime: 2024-07-31 14:14:43
 * 
-->
<template>
  <div id="app">
    <router-view />
    <MixLoginDialog :show.sync="show" />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    MixLoginDialog: () => import("@/components/MixLoginDialog"),
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.$bus.$on("showLogin", () => {
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.$off("showLogin");
  },
};
</script>
<style lang="scss">
</style>
