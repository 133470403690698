import Cookies from 'js-cookie'
// import { getUser, getChild } from '@/api';
const state = {
  device: 'desktop',
  systemChild: []
}

const mutations = {
  systemChild (state, data) {
    state.systemChild = data
  }
}

const actions = {
  async getChild ({ commit }) {
    try {
      let res = await getChild();
      commit('systemChild', res.data)
    } catch (error) {

    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
